import { api } from '@redux/api/api'
import { TagTypes } from '@redux/api/tagTypes'
import type { AccountResetResponseType } from '@redux/features/authorization/types/accountReset'
import type {
  CheckEmailType,
  LoginByCodeType,
  LoginType,
  ResponseCheckEmailType,
  UserType,
} from '@redux/features/authorization/types/login'
import type { ResponseRegistrationType } from '@redux/features/authorization/types/registartion'

import { urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'
import authStorage from '@storageServices/storageEntities/auth'
import cleanUserDataStorage from '@utils/browserStorages/cleanUserDataStorage'
import type { RegistrationFormType } from '@utils/forms/authorization/registration'
import type { AccountPasswordFormType } from '@utils/forms/authorization/resetPassword'

export const fixedPostLoginCacheKey = 'postLogin'

const authorizationApi = api.injectEndpoints({
  endpoints: build => ({
    postAccountReset: build.mutation<AccountResetResponseType, AccountPasswordFormType>({
      query: body => ({
        body,
        method: 'POST',
        url: urlVersionPrefix + 'user/reset',
      }),
    }),
    postCheckEmail: build.mutation<ResponseCheckEmailType, CheckEmailType>({
      query: body => ({
        body,
        method: 'POST',
        url: urlVersionPrefixV2 + 'user/check',
      }),
    }),
    postLogin: build.mutation<UserType, LoginType>({
      invalidatesTags: [TagTypes.PostLogin],
      query: body => ({
        body: body,
        method: 'POST',
        url: urlVersionPrefix + 'user/login',
      }),
      transformResponse: (response: UserType) => {
        response.auth_token &&
          authStorage.setValue({
            roles: response.current_user?.roles,
            userId: response.current_user?.uid,
            'X-Auth-Token': response.auth_token,
          })
        return response
      },
    }),
    postLoginByCode: build.mutation<UserType, LoginByCodeType>({
      invalidatesTags: [TagTypes.PostLogin],
      query: body => ({
        body: body,
        method: 'POST',
        url: urlVersionPrefix + 'user/login/code',
      }),
      transformResponse: (response: UserType) => {
        response.auth_token &&
          authStorage.setValue({
            roles: response.current_user?.roles,
            userId: response.current_user?.uid,
            'X-Auth-Token': response.auth_token,
          })
        return response
      },
    }),
    postLogout: build.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: urlVersionPrefixV2 + 'user/logout',
      }),
      transformResponse: () => {
        cleanUserDataStorage()
      },
    }),
    postRegistration: build.mutation<ResponseRegistrationType, RegistrationFormType>({
      query: body => ({
        body,
        method: 'POST',
        url: urlVersionPrefix + 'user/register',
      }),
    }),
  }),
})

export const {
  endpoints: authorizationEndpoints,
  usePostAccountResetMutation,
  usePostCheckEmailMutation,
  usePostLoginByCodeMutation,
  usePostLoginMutation,
  usePostRegistrationMutation,
} = authorizationApi
