import { xor } from 'lodash'

import type { PreferencesKey } from '@redux/features/preferences/constants/preferencesKey'
import type { DataClientDetailsType } from '@redux/features/preferences/types/clientDetails'
import type { IPreferencesState } from '@redux/features/preferences/types/preferencesState'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IPreferencesState = {
  seat: {
    currentLeg: 1,
    selectedSeatPreferences: {},
  },
}

export const preferencesSlice = createSlice({
  initialState,
  name: 'preferences',
  reducers: {
    setActivePreferences: (state, action: PayloadAction<PreferencesKey[]>) => {
      state.activePreference = action.payload
    },
    setCurrentLeg: (state, action: PayloadAction<number>) => {
      state.seat.currentLeg = action.payload
    },

    setInitialSeatPreferences: (state, action: PayloadAction<{ leg: number; value: string[] }>) => {
      state.seat.selectedSeatPreferences[action.payload.leg] = action.payload.value
    },
    setSelectedSeatPreferences: (state, action: PayloadAction<{ leg: number; value: string }>) => {
      state.seat.selectedSeatPreferences[action.payload.leg] = xor(
        state.seat.selectedSeatPreferences[action.payload.leg],
        [action.payload.value]
      )
    },
    setSpecialRequests: (state, action: PayloadAction<DataClientDetailsType>) => {
      state.specialRequests = action.payload
    },

    setSpecialRequestsNotes: (state, action: PayloadAction<string>) => {
      state.specialRequests = { ...state.specialRequests, user_notes: action.payload }
    },
  },
})

export const {
  setActivePreferences,
  setCurrentLeg,
  setInitialSeatPreferences,
  setSelectedSeatPreferences,
  setSpecialRequests,
  setSpecialRequestsNotes,
} = preferencesSlice.actions

export default preferencesSlice.reducer
