import { api } from '@redux/api/api'

import type { IStation } from '@Types/stations/station'
import { urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'

const trainDataApi = api.injectEndpoints({
  endpoints: build => ({
    getCarrier: build.query<ICarrierShort, number>({
      keepUnusedDataFor: 900,
      query: carrierId => ({
        url: urlVersionPrefix + `carrier/${carrierId}`,
      }),
    }),
    getMedia: build.query<ISlideShow, number>({
      keepUnusedDataFor: 900,
      query: mediaId => ({
        url: urlVersionPrefixV2 + `media/${mediaId}`,
      }),
    }),
    getStation: build.query<IStation, number | string>({
      keepUnusedDataFor: 900,
      query: stationId => ({
        url: urlVersionPrefix + `station/${stationId}`,
      }),
    }),
  }),
})

export const {
  endpoints: { getCarrier, getMedia, getStation },
  useGetCarrierQuery,
  useGetMediaQuery,
  useGetStationQuery,
} = trainDataApi
