import { PaymentFieldsName } from '@utils/forms/payment/paymentFieldsName'
import { PaymentFormKeys } from '@utils/forms/payment/paymentFormKeys'
import type { PaymentFormSavedType, PaymentFormType } from '@utils/forms/payment/types'

const notSavedPaymentFields: (keyof PaymentFormType)[] = [
  PaymentFieldsName.termsAndConditions,
  PaymentFormKeys.paymentData,
]

const getFormValuesForRedux = (paymentFormType: PaymentFormType) => {
  const result: PaymentFormSavedType = { ...paymentFormType }

  notSavedPaymentFields.forEach(fieldName => {
    if (fieldName in result) delete (result as PaymentFormType)[fieldName]
  })

  return result
}

export default getFormValuesForRedux
