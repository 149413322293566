import { api } from '@redux/api/api'
import { TagTypes } from '@redux/api/tagTypes'
import type { CancellationPayloadType, CancellationResponseType } from '@redux/features/order/types/cancellation'
import type { IPassengersInfo } from '@redux/features/order/types/passengersInfo'
import type { IAvailableActions, ITrainOrderData } from '@redux/features/order/types/storeOrder'

import type { PassengersFieldsType } from '@Types/common/passengers'
import { urlVersionPrefixV2, urlVersionPrefixV3 } from '@constants/api/api'
import { type PassengersFormType } from '@utils/forms/passengers/types'
import { preparePostPassengersData } from '@utils/passengers/preparePostPassengersPayload'

const orderApi = api.injectEndpoints({
  endpoints: build => ({
    addOrderPassengers: build.mutation<
      void,
      {
        bookingId: string
        passengersForm: PassengersFormType
        requiredField: PassengersFieldsType[]
        type?: 'order_page'
      }
    >({
      invalidatesTags: [TagTypes.PostPassengers],
      query: ({ bookingId, passengersForm, requiredField, type = 'order_page' }) => ({
        body: preparePostPassengersData({ passengersForm, requiredField }),
        method: 'POST',
        url: urlVersionPrefixV2 + `order/${bookingId}/${type}/passenger`,
      }),
    }),
    cancelOrder: build.mutation<CancellationResponseType, { bookingId: string; payload: CancellationPayloadType }>({
      invalidatesTags: [TagTypes.GetOrder],
      query: ({ bookingId, payload }) => ({
        body: payload,
        method: 'POST',
        url: urlVersionPrefixV3 + `internal/train/order/${bookingId}/cancellation`,
      }),
    }),
    getOrder: build.query<ITrainOrderData, string>({
      keepUnusedDataFor: 0,
      providesTags: [TagTypes.GetOrder, TagTypes.PostPassengers, TagTypes.PostLogin],
      query: bookingId => ({
        url: urlVersionPrefixV3 + `internal/train/order/${bookingId}`,
      }),

      transformResponse: (
        response: Omit<ITrainOrderData, 'availableActions'> & {
          availableActions: IAvailableActions[]
        }
      ) => {
        return {
          ...response,
          availableActions: response.availableActions.reduce(
            (acc, action) => ({ ...acc, [action.id]: action }),
            {} as ITrainOrderData['availableActions']
          ),
        }
      },
    }),
    getOrderPassengers: build.query<IPassengersInfo, { bookingId: string; type?: 'order_page' }>({
      providesTags: [TagTypes.PostPassengers],
      query: ({ bookingId, type = 'order_page' }) => ({
        url: urlVersionPrefixV2 + `order/${bookingId}/${type}/passengers/info`,
      }),
    }),
    postpone: build.mutation<void, { bookingId: string }>({
      invalidatesTags: [TagTypes.GetOrder],
      query: ({ bookingId }) => ({
        body: {},
        method: 'POST',
        url: urlVersionPrefixV3 + `internal/train/order/${bookingId}/postponement`,
      }),
    }),
  }),
})

export const {
  endpoints: orderEndpoints,
  useAddOrderPassengersMutation,
  useCancelOrderMutation,
  useGetOrderPassengersQuery,
  useGetOrderQuery,
  usePostponeMutation,
} = orderApi
