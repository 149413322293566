import type {
  BasicFields,
  CreditCardPayload,
  PaymentPayloadType,
} from '@Types/payment/paymentPayload/paymentRequestPayload'
import { PaymentFieldsName } from '@utils/forms/payment/paymentFieldsName'
import { PaymentFormKeys } from '@utils/forms/payment/paymentFormKeys'
import type { PaymentFormType } from '@utils/forms/payment/types'

const convertPutPaymentPayload = (formData: PaymentFormType): PaymentPayloadType => {
  const result: BasicFields = {
    email: formData[PaymentFormKeys.additionalData][PaymentFieldsName.email] as string,
    paymentMethod: formData[PaymentFieldsName.paymentMethod].name,
    phoneNumber: formData[PaymentFormKeys.additionalData][PaymentFieldsName.phoneNumber] as string,
    returnUrl: window.location.href,
  }

  if (result.paymentMethod === 'credit_card') {
    const expirationDate = formData[PaymentFormKeys.paymentData][PaymentFieldsName.cardExpirationDate].split(' / ')

    const cardFields: CreditCardPayload = {
      cardNumber: formData[PaymentFormKeys.paymentData][PaymentFieldsName.cardNumber].replaceAll(' ', ''),
      cardType: formData[PaymentFormKeys.paymentData][PaymentFieldsName.cardType],
      country: 'US',
      expirationMonth: expirationDate[0],
      expirationYear: `20${expirationDate[1]}`,
      nameOnCard: formData[PaymentFormKeys.paymentData][PaymentFieldsName.cardOwner],
      secretCode: formData[PaymentFormKeys.paymentData][PaymentFieldsName.cardCode],
    }

    return { ...result, ...cardFields }
  }

  return result
}

export default convertPutPaymentPayload
