import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'
import { setPageInteraction } from '@reduxCommonApp/commonApp.slice'

export default () => {
  const dispatch = useAppDispatch()
  const isPageInteraction = useAppSelector(selectIsPageInteraction)

  useEffect(() => {
    if (!isPageInteraction) {
      const interactions = ['mousemove', 'click', 'keydown', 'scroll']

      interactions.forEach(function (interaction) {
        window.addEventListener(interaction, () => dispatch(setPageInteraction(true)), { once: true })
      })
    }
  }, [])
}
