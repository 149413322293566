import type { Dayjs } from 'dayjs'

import type { PostPassengersItemType, PostPassengersPayloadType } from '@redux/features/checkout/types/passengers'

import type { PassengersCategoryType, PassengersFieldsType } from '@Types/common/passengers'
import dateFormats from '@constants/dates/dateFormats'
import passengersDateFields from '@utils/forms/passengers/passengersDateFields'
import type { PassengersFormType } from '@utils/forms/passengers/types'
import splitFullName from '@utils/forms/passengers/utils/splitFullName'

export const preparePostPassengersData = ({
  passengersForm,
  requiredField,
}: {
  passengersForm: PassengersFormType
  requiredField: PassengersFieldsType[]
}): PostPassengersPayloadType => {
  const passengers: PostPassengersItemType[] = []
  Object.entries(passengersForm).forEach(([category, categoryItems]) => {
    const categoryPassengers = categoryItems.map(item => {
      let result = { ...item }
      passengersDateFields.forEach(dateField => {
        if (result[dateField]) {
          ;(result as PostPassengersItemType)[dateField] = (result[dateField] as Dayjs).format(
            dateFormats['2023-01-01']
          )
        }
      })
      if (result.full_name) {
        ;(result as PostPassengersItemType) = {
          ...splitFullName(result.full_name),
          ...result,
        } as PostPassengersItemType
      }
      if (requiredField.some(field => !result[field])) {
        ;(result as PostPassengersItemType).missing = true
        requiredField.forEach(field => {
          if (!result[field]) {
            ;(result as Omit<PostPassengersItemType, 'category'>)[field as Exclude<PassengersFieldsType, 'category'>] =
              ''
          }
        })
      }
      if (!result.category) {
        result.category = category as PassengersCategoryType
      }
      return result as PostPassengersItemType
    })
    passengers.push(...categoryPassengers)
  })

  return { passengers }
}
