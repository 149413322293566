// This code is designed for multisite use, and each site will have a similar configuration. In our case, the site
// codes are rn and hst. To make these files work, it's necessary to copy the current
// file named [SITE_CODE].js.dist into rn.js and hst.js.
// The public section is designated to make the configs available on both
// the server and the client side, whereas the private section is accessible only on the server side.
// To put it simply, this multi-site configuration, via specific site codes, provides
// flexibility in defining settings for different environments, such as public and private
// settings for varying levels of accessibility depending on the requirement.



module.exports = {
  public: {
      theme: 'light',
      backendApiUrl: 'https://back.devhst32.thetrainlink.com',
      xApiUserKey: 'dev',
      xApiUserKeyAdditional: '',
      additionalBackendApiUrl: '',
      googleTagManagerId: '',
      envType: 'dev'
    },
    private: {
      recaptchaSiteKey: '',
      tildaSecretKey: '',
      tildaPublicKey: '',
      resetCache: {
        resetCacheKey: 'dev'
      }
    }
};

