import { additionalBaseUrl, externalApiPrefix, externalApiPrefixV2, urlVersionPrefixV2 } from '@constants/api/api'
import type { PaymentMethodsName } from '@constants/payment'

export default {
  booking: externalApiPrefix, // /api/v1/external-booking
  bookingCurrency: (bookingId: string) => `${externalApiPrefix}/${bookingId}/currency-update`, // /api/v1/external-booking/{bookingId}/currency-update
  bookingOrderItemInfo: (bookingId: string) => `${externalApiPrefix}/${bookingId}/order-item/info`, // /api/v1/external-booking/{bookingId}/order-item/info
  bookingPaidServiceInfo: (bookingId: string, stepId: string) =>
    `${externalApiPrefixV2}/${bookingId}/paid-service/${stepId}/info`, // /api/v2/external-booking/{bookingId}/paid-service/{stepId}/info
  bookingPayment: (bookingId: string) => `${externalApiPrefix}/${bookingId}/payment`, // /api/v1/external-booking/{bookingId}/payment
  bookingService: (bookingId: string) => `${externalApiPrefixV2}/${bookingId}/service`, // /api/v2/external-booking/{bookingId}/service
  bookingTotalPrice: (bookingId: string, paymentMethod: PaymentMethodsName) =>
    `${urlVersionPrefixV2}booking/${bookingId}/total-price/${paymentMethod}`, // /api/v2/booking/{bookingId}/total-price/{paymentMethod}
  checkoutPaymentMethods: (bookingId: string) => `${externalApiPrefix}/${bookingId}/payment/info`, // /api/v2/external-booking/{bookingId}/payment/info
  passengers: (bookingId: string) => `${externalApiPrefix}/${bookingId}/passenger`, // /api/v1/external-booking/{bookingId}/passenger
  preBooking: (bookingId: string) => `${externalApiPrefix}/${bookingId}/prebooking`, // /api/v1/external-booking/{bookingId}/prebooking
  search: `${additionalBaseUrl}${urlVersionPrefixV2}timetable`, // /api/v2/timetable
  searchBatch: `${additionalBaseUrl}${urlVersionPrefixV2}timetable/batch`, // /api/v2/timetable/batch
  timetableCoachClasses: `${additionalBaseUrl}${urlVersionPrefixV2}timetable/coach-class`, // /api/v2/timetable/coach-class
  voucher: (bookingId: string) => `${urlVersionPrefixV2}booking/${bookingId}/voucher`, // /api/v2/booking/{bookingId}/voucher
}
