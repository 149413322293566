import { colorsList } from '@themes/colors/colorsList'

export const lightThemeShadows = {
  big: '0 0 200px #0000000D', //5%
  button: '0 0 50px #0000001A', //10%
  collapseHead: '0 28px 90px 0 #0000000D', //5%
  modal: '0 0 200px #0000001A', //10%
  small: '0 0 20px #00000026', //15%
  xSmall: '0 0 10px #00000026', //15%
}

export const darkThemeShadows: typeof lightThemeShadows = {
  big: `0 4px 100px 20px ${colorsList.shadow}66`,
  button: `0 0 50px 0 ${colorsList.shadow}26`,
  collapseHead: '0 28px 90px 0 #00000040', //25%
  modal: `0 4px 100px 20px ${colorsList.shadow}33`,
  small: `0 0 20px 0 ${colorsList.shadow}26`,
  xSmall: `0 0 10px ${colorsList.shadow}26`,
}

export const railNinjaLightThemeShadows: typeof lightThemeShadows = {
  big: '0 0 200px #0000000D', //5%
  button: 'none', //10%
  collapseHead: '0 28px 90px 0 #0000000D', //5%
  modal: '0 0 200px #0000001A', //10%
  small: '0 0 20px #00000026', //15%
  xSmall: '0 0 10px #00000026',
}

export const railNinjaDarkThemeShadows: typeof lightThemeShadows = {
  big: '0 4px 100px 20px #07090F66',
  button: 'none',
  collapseHead: '0 28px 90px 0 #0000000D', //5%
  modal: '0 4px 100px 20px #07090F33',
  small: '0 0 20px 0 #07090F26',
  xSmall: '0 0 10px #00000026',
}
