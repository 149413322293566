import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import AboutWithVideo from '@constants/locales/en/About with video.json'
import AdvanceTicketReservation from '@constants/locales/en/Advance ticket reservation.json'
import Authorization from '@constants/locales/en/Authorization.json'
import Banner from '@constants/locales/en/Banner.json'
import CancelTicket from '@constants/locales/en/Cancel ticket.json'
import Cart from '@constants/locales/en/Cart.json'
import ConfirmModal from '@constants/locales/en/Confirm modal.json'
import DownloadMobileApp from '@constants/locales/en/Download mobile app.json'
import ExtraServices from '@constants/locales/en/Extra Services.json'
import FareInfo from '@constants/locales/en/Fare info.json'
import FastestTrains from '@constants/locales/en/Fastest trains.json'
import Filters from '@constants/locales/en/Filters.json'
import Footer from '@constants/locales/en/Footer.json'
import Header from '@constants/locales/en/Header.json'
import HighSpeedAwards from '@constants/locales/en/Hight speed awards.json'
import Invoice from '@constants/locales/en/Invoice.json'
import MainBurgerMenu from '@constants/locales/en/Main Burger Menu.json'
import NotFound from '@constants/locales/en/Not Found.json'
import Notifications from '@constants/locales/en/Notifications.json'
import OrderPage from '@constants/locales/en/Order page.json'
import PassengersForm from '@constants/locales/en/Passengers form.json'
import Password from '@constants/locales/en/Password.json'
import PaymentForm from '@constants/locales/en/Payment form.json'
import PopularQuestions from '@constants/locales/en/Popular questions.json'
import PopularRoutes from '@constants/locales/en/Popular routes.json'
import PopularTrainRoutes from '@constants/locales/en/Popular train routes.json'
import Postpone from '@constants/locales/en/Postpone.json'
import Preferences from '@constants/locales/en/Preferences.json'
import RecommendedRoutes from '@constants/locales/en/Recommended routes.json'
import Review from '@constants/locales/en/Review.json'
import RouteInfo from '@constants/locales/en/Route info.json'
import SearchForm from '@constants/locales/en/Search form.json'
import Timetable from '@constants/locales/en/Timetable.json'
import TotalPrice from '@constants/locales/en/Total price.json'
import TrainInformation from '@constants/locales/en/Train information.json'
import TravelBetter from '@constants/locales/en/Travel better.json'
import TravelInformation from '@constants/locales/en/Travel information.json'
import Validation from '@constants/locales/en/Validation.json'
import Voucher from '@constants/locales/en/Voucher.json'
import common from '@constants/locales/en/common.json'

const resources = {
  defaultNS: common,
  en: {
    ['About with video']: AboutWithVideo,
    ['Advance ticket reservation']: AdvanceTicketReservation,
    Authorization,
    Banner,
    ['Cancel ticket']: CancelTicket,
    Cart,
    common,
    ['Confirm modal']: ConfirmModal,
    ['Download mobile app']: DownloadMobileApp,
    ['Extra Services']: ExtraServices,
    ['Fare info']: FareInfo,
    ['Fastest trains']: FastestTrains,
    Filters,
    Footer,
    Header,
    ['High speed awards']: HighSpeedAwards,
    Invoice,
    ['Main Burger Menu']: MainBurgerMenu,
    ['Not Found']: NotFound,
    Notifications,
    ['Order page']: OrderPage,
    ['Passengers form']: PassengersForm,
    Password,
    ['Payment form']: PaymentForm,
    ['Popular questions']: PopularQuestions,
    ['Popular routes']: PopularRoutes,
    ['Popular train routes']: PopularTrainRoutes,
    Postpone,
    Preferences,
    ['Recommended routes']: RecommendedRoutes,
    Review,
    ['Route info']: RouteInfo,
    ['Search form']: SearchForm,
    Timetable,
    ['Total price']: TotalPrice,
    ['Train information']: TrainInformation,
    ['Travel better']: TravelBetter,
    ['Travel information']: TravelInformation,
    Validation,
    Voucher,
  },
}

void i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  resources,
})

export default i18n
