import { api } from '@redux/api/api'
import type { IUserInfo } from '@redux/features/user/types/userInfo'

import { urlVersionPrefixV2 } from '@constants/api/api'
import authStorage from '@storageServices/storageEntities/auth'
import userInfoStorage from '@storageServices/storageEntities/userInfoStorage'
import cleanUserDataStorage from '@utils/browserStorages/cleanUserDataStorage'

const userApi = api.injectEndpoints({
  endpoints: build => ({
    getUserInfo: build.query<IUserInfo, number | string>({
      query: userId => ({
        method: 'GET',
        url: urlVersionPrefixV2 + `user/${userId}`,
      }),
      transformErrorResponse: error => {
        cleanUserDataStorage()
        return error
      },
      transformResponse: (response: IUserInfo) => {
        response.mail && userInfoStorage.addValues({ email: response.mail })
        response.api_key &&
          authStorage.setValue({
            roles: response.roles,
            userId: response.uid.toString(),
            'X-Auth-Token': response.api_key,
          })
        return response
      },
    }),
  }),
})

export const { endpoints: userEndpoints, useGetUserInfoQuery, useLazyGetUserInfoQuery } = userApi
