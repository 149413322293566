export enum TagTypes {
  BookingOrderInfo = 'bookingOrderInfo',
  GetBookingService = 'getBookingService',
  GetOrder = 'getOrder',
  PostBooking = 'postBooking',
  PostLogin = 'postLogin',
  PostPassengers = 'postPassengers',
  PostPayment = 'postPayment',
  TotalPrice = 'totalPrice',
}
