export const basicRequiredFields = ['email', 'phone_number']

export const creditCardRequiredFields = [
  'card_number',
  'card_type',
  'card_owner',
  'card_expiration_date',
  'card_code',
  ...basicRequiredFields,
]

export const appleGooglePayRequiredFields = ['nonce', 'email', ...basicRequiredFields]
