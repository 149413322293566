export enum PaymentFieldsName {
  cardCode = 'card_code',
  cardExpirationDate = 'card_expiration_date',
  cardInfo = 'cardInfo',
  cardNumber = 'card_number',
  cardOwner = 'card_owner',
  cardType = 'card_type',
  confirmEmail = 'confirm_email',
  email = 'email',
  nonce = 'nonce',
  paymentMethod = 'payment_method',
  phoneNumber = 'phone_number',
  termsAndConditions = 'terms_and_conditions',
  voucher = 'voucher',
}
