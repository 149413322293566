import { api } from '@redux/api/api'
import { TagTypes } from '@redux/api/tagTypes'
import type { UpcomingServiceOrdersType, UpcomingStoreOrdersType } from '@redux/features/myTrips/types/upcomingTrips'

import type { IStation } from '@Types/stations/station'
import { urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'

const myTripsApi = api.injectEndpoints({
  endpoints: build => ({
    getServiceOrderStations: build.query<
      Record<number, IStation>,
      { serviceOrderId: number | string; userId: UserIdType }
    >({
      query: ({ serviceOrderId, userId }) => ({
        method: 'GET',
        url: urlVersionPrefix + `user/${userId}/service-orders/${serviceOrderId}/stations`,
      }),
    }),
    getStoreOrders: build.query<void, UserIdType>({
      query: userId => ({
        method: 'GET',
        url: urlVersionPrefixV2 + `user/${userId}/store-orders`,
      }),
    }),
    getUpcomingServiceOrders: build.query<UpcomingServiceOrdersType, { hash: string; userId: UserIdType }>({
      query: ({ hash, userId }) => ({
        method: 'GET',
        url: urlVersionPrefix + `user/${userId}/store-orders/${hash}/service-orders/upcoming`,
      }),
    }),
    getUpcomingStoreOrders: build.query<UpcomingStoreOrdersType, UserIdType>({
      providesTags: [TagTypes.PostPayment],
      query: userId => ({
        method: 'GET',
        url: urlVersionPrefix + `user/${userId}/store-orders/upcoming`,
      }),
    }),
  }),
})

export const {
  endpoints: myTripsEndpoints,
  useGetServiceOrderStationsQuery,
  useGetStoreOrdersQuery,
  useGetUpcomingServiceOrdersQuery,
  useGetUpcomingStoreOrdersQuery,
} = myTripsApi
