import { api } from '@redux/api/api'
import type { BannerBlockInfoType } from '@redux/features/homePage/types/bannerBlockInfo'
import type { HomePageEntityType } from '@redux/features/homePage/types/blocksHomePage'
import type { ParagraphType } from '@redux/features/homePage/types/paragraph'
import type { PopularRoutesType } from '@redux/features/homePage/types/popularRoutes'
import { getParamPaymentMethods } from '@redux/features/homePage/utils/getParamPaymentMethods'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import type { PaymentMethodType } from '@Types/blocks/paymentMethods'
import { urlVersionPrefix } from '@constants/api/api'

const homePageApi = api.injectEndpoints({
  endpoints: build => ({
    getBannerBlockInfo: build.query<BannerBlockInfoType, number | string>({
      keepUnusedDataFor: 600,
      query: node => ({
        url: urlVersionPrefix + `banner-block-info/${node}`,
      }),
    }),
    getBlock: build.query<ExtendBlocksType, number>({
      keepUnusedDataFor: 600,
      query: blockId => ({
        url: urlVersionPrefix + `admin/content/block/${blockId}`,
      }),
    }),
    getHomePageEntity: build.query<HomePageEntityType, void>({
      keepUnusedDataFor: 600,
      query: () => ({
        url: urlVersionPrefix + 'pages/front',
      }),
    }),
    getParagraph: build.query<ParagraphType, number>({
      keepUnusedDataFor: 600,
      query: paragraphId => ({
        url: urlVersionPrefix + `entity/paragraph/${paragraphId}`,
      }),
    }),
    getPaymentMethods: build.query<PaymentMethodType[], string[]>({
      keepUnusedDataFor: 600,
      query: (paymentMethods?: string[]) => ({
        url: urlVersionPrefix + `payment-methods?${getParamPaymentMethods(paymentMethods)}`,
      }),
    }),
    getPopularRoutesMenuItems: build.query<PopularRoutesType, string>({
      keepUnusedDataFor: 600,
      query: (menuName: string) => ({
        url: urlVersionPrefix + `menu-items/${menuName}`,
      }),
    }),
  }),
})
export const {
  endpoints: homePageEndpoints,
  useGetBannerBlockInfoQuery,
  useGetBlockQuery,
  useGetHomePageEntityQuery,
  useGetParagraphQuery,
  useGetPaymentMethodsQuery,
  useGetPopularRoutesMenuItemsQuery,
} = homePageApi

export const {
  getBannerBlockInfo,
  getBlock,
  getHomePageEntity,
  getParagraph,
  getPaymentMethods,
  getPopularRoutesMenuItems,
} = homePageEndpoints
