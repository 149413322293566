export const getGradient = (gradientFillOpacity: number) => {
  return {
    gradientFillOpacity,
    grayToWhite: `linear-gradient(180deg, rgba(0, 0, 0, ${gradientFillOpacity}) 0%, rgba(0, 0, 0, 0) 100%)`,
    grayToWhiteHorizontal: `linear-gradient(90deg, rgba(0, 0, 0, ${gradientFillOpacity}) 0%, rgba(0, 0, 0, 0) 100%)`,
  }
}

export const gradient = {
  darkBlueToBlack: 'linear-gradient(180deg, #191E31 0.5%, #0D0F1A 100%)',
}
