import type { PostPaymentPayloadType } from '@redux/features/checkout/types/payment'

import { PaymentFieldsName } from '@utils/forms/payment/paymentFieldsName'
import { PaymentFormKeys } from '@utils/forms/payment/paymentFormKeys'
import type { PaymentFormType } from '@utils/forms/payment/types'

export const preparePostPaymentData = (paymentForm: PaymentFormType): PostPaymentPayloadType => {
  const payment_method = paymentForm[PaymentFieldsName.paymentMethod].name
  const additional_data = paymentForm[PaymentFormKeys.additionalData]
  const payment_data = paymentForm[PaymentFormKeys.paymentData]
  const terms_and_conditions = paymentForm[PaymentFieldsName.termsAndConditions]
  const billing_profile = paymentForm[PaymentFormKeys.billingProfile]

  delete additional_data.confirm_email
  if (payment_data?.[PaymentFieldsName.cardNumber]) {
    payment_data[PaymentFieldsName.cardNumber] = payment_data[PaymentFieldsName.cardNumber].replaceAll(' ', '')
  }

  if (payment_data?.[PaymentFieldsName.cardExpirationDate]) {
    payment_data[PaymentFieldsName.cardExpirationDate] = payment_data[PaymentFieldsName.cardExpirationDate].replaceAll(
      ' ',
      ''
    )
  }

  return { additional_data, billing_profile, payment_data, payment_method, terms_and_conditions }
}
