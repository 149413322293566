import type { CartLegsType } from '@redux/features/cart/types/cartState'
import type { PostBookingPayloadType } from '@redux/features/checkout/types/booking'

export const preparePostBookingData = (cartLegs: CartLegsType[]): PostBookingPayloadType => {
  const legs: PostBookingPayloadType['legs'] = cartLegs.reduce((acc, { coachClass, fare, train }, index) => {
    acc[index + 1] = {
      arrival_datetime: train.arrival_datetime,
      arrival_station: train.arrival_station.uuid,
      carrier_uuid: train.carrier?.uuid || '',
      coach_class_uuid: coachClass.coach_class.uuid,
      departure_datetime: train.departure_datetime,
      departure_station: train.departure_station.uuid,
      fare_uuid: fare.fare!.uuid,
      price: fare.total_price?.number || 0,
      train_number: train.train_number || '',
    }
    return acc
  }, {} as PostBookingPayloadType['legs'])
  return {
    baseUrl: location.origin,
    legs,
    passengers: { ...cartLegs[0].passengers, children_age: cartLegs[0].passengers.children_age || [] },
  }
}
