import { api } from '@redux/api/api'
import type { PaymentMethodsListType } from '@redux/features/checkout/types/payment'

import type { IInvoiceInfo } from '@Types/invoice/invoiceInfo'
import type { PaymentResponseType } from '@Types/payment/paymentResponse/putPaymentResponse'
import { urlVersionPrefixV3 } from '@constants/api/api'
import type { PaymentMethodsName } from '@constants/payment'
import paymentStorage from '@storageServices/storageEntities/payment'
import type { PaymentFormType } from '@utils/forms/payment/types'
import getPaymentMethodsRequiredFields from '@utils/payment/getPymentMethodsRequiredFields'
import { camelToUnderscore } from '@utils/string/caseUtils'

import convertPutPaymentPayload from './utils/convertPutPaymentPayload'

export const fixedPutInvoicePaymentCacheKey = 'putInvoicePayment'
export const fixedPatchInvoicePaymentCacheKey = 'patchInvoicePayment'

const getBasicInvoiceUrl = (invoiceIdOrNumber: number | string) =>
  `${urlVersionPrefixV3}internal/invoice/${invoiceIdOrNumber}`
const basicInvoicePaymentUrl = (invoiceIdOrNumber: number | string) =>
  `${getBasicInvoiceUrl(invoiceIdOrNumber)}/payment`

const invoiceApi = api.injectEndpoints({
  endpoints: build => ({
    getInvoice: build.query<IInvoiceInfo, number | string>({
      query: invoiceIdOrNumber => ({
        method: 'GET',
        url: getBasicInvoiceUrl(invoiceIdOrNumber),
      }),
    }),
    getInvoicePayment: build.query<PaymentResponseType, { invoiceIdOrNumber: number | string }>({
      query: ({ invoiceIdOrNumber }) => ({
        method: 'GET',
        url: basicInvoicePaymentUrl(invoiceIdOrNumber),
      }),
    }),
    getInvoicePaymentMethods: build.query<PaymentMethodsListType, { invoiceIdOrNumber: number | string }>({
      keepUnusedDataFor: 0,
      query: ({ invoiceIdOrNumber }) => ({
        keepUnusedDataFor: 0,
        method: 'GET',
        url: `${basicInvoicePaymentUrl(invoiceIdOrNumber)}-method`,
      }),
      transformResponse: (response: Array<Record<string, any>>) => {
        return response.map(method => {
          method.required_fields = getPaymentMethodsRequiredFields(method.id as PaymentMethodsName)
          return Object.fromEntries(
            Object.entries(method).map(([key, values]) => {
              return [key === 'id' ? 'name' : values === 'credit_card' ? camelToUnderscore(key) : key, values]
            })
          )
        }) as PaymentMethodsListType
      },
    }),
    patchInvoicePaymentAction: build.mutation<
      PaymentResponseType,
      {
        data?: Record<string, string>
        invoiceIdOrNumber: number | string
        paramsFromPaymentSystem?: string
        withGetParams?: boolean
      }
    >({
      query: ({ data, invoiceIdOrNumber }) => {
        const lastAction = paymentStorage.getValueByKey('lastActions')?.[invoiceIdOrNumber]
        return {
          body: { data, lastAction },
          method: 'PATCH',
          url: basicInvoicePaymentUrl(invoiceIdOrNumber),
        }
      },
    }),
    putInvoicePayment: build.mutation<
      PaymentResponseType,
      { invoiceIdOrNumber: number | string; paymentForm: PaymentFormType }
    >({
      query: ({ invoiceIdOrNumber, paymentForm }) => ({
        body: convertPutPaymentPayload(paymentForm),
        method: 'PUT',
        url: basicInvoicePaymentUrl(invoiceIdOrNumber),
      }),
    }),
  }),
})

export const {
  endpoints: invoiceEndpoints,
  useGetInvoicePaymentMethodsQuery,
  useGetInvoiceQuery,
  useLazyGetInvoicePaymentQuery,
  usePatchInvoicePaymentActionMutation,
  usePutInvoicePaymentMutation,
} = invoiceApi
