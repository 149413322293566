import { isEqual } from 'lodash'

import type { PaymentState } from '@redux/features/payment/types/paymentState'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { invoiceEndpoints } from '../invoice/invoice.api'

const initialState: PaymentState = {}

export const paymentSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(
        invoiceEndpoints.getInvoicePayment.matchFulfilled,
        invoiceEndpoints.putInvoicePayment.matchFulfilled,
        invoiceEndpoints.patchInvoicePaymentAction.matchFulfilled
      ),
      (state, { meta, payload }) => {
        if (meta.arg.endpointName !== invoiceEndpoints.getInvoicePayment.name || !isEqual(payload, state.paymentData)) {
          state.paymentData = payload
        }
      }
    )
  },
  initialState,
  name: 'payment',
  reducers: {
    clearPaymentState: () => initialState,
  },
})

export const { clearPaymentState } = paymentSlice.actions

export default paymentSlice.reducer
