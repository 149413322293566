export const sizes = {
  contentMaxWidth: 1224,
  desktopL: 1440,
  desktopM: 1366,
  desktopMinWidth: 1024,
  desktopS: 1280,
  maxWidth: 1440,
  mobileM: 480,
  mobileMaxWidth: 767,
  mobileS: 360,
  mobileXS: 320,
  tabletM: 850,
  tabletMaxWidth: 1023,
  tabletMinWidth: 768,
}
