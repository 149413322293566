import type { AppProps } from 'next/app'
import localFont from 'next/font/local'
import Head from 'next/head'
import type { NextComponentType, NextPageContext } from 'next/types'
import type { FC } from 'react'
import { Provider } from 'react-redux'

import { wrapper } from '@redux/store'

import ThemeProvider from '@hocs/providers/ThemeProvider'
import useUserPageInteraction from '@hooks/global/useUserPageInteraction'
import GTMScripts from '@services/analytics/GTMScripts'
import getAppConfig from '@services/config/config'
import '@services/dayJs/prezerveTimeZone'
import CustomScripts from '@services/htmlScripts/CustomScripts'
import '@services/i18n'

const DMSans = localFont({
  display: 'swap',
  src: [
    {
      path: '../../../../../public/fonts/DMSans-Regular.woff2',
      style: 'normal',
      weight: 'normal',
    },
    {
      path: '../../../../../public/fonts/DMSans-Medium.woff2',
      style: 'normal',
      weight: '500',
    },
    {
      path: '../../../../../public/fonts/DMSans-MediumItalic.woff2',
      style: 'italic',
      weight: '500',
    },
    {
      path: '../../../../../public/fonts/DMSans-BoldItalic.woff2',
      style: 'italic',
      weight: '700',
    },
    {
      path: '../../../../../public/fonts/DMSans-Italic.woff2',
      style: 'italic',
      weight: 'normal',
    },
    {
      path: '../../../../../public/fonts/DMSans-Bold.woff2',
      style: 'normal',
      weight: '700',
    },
  ],
})

const { publicRuntimeConfig } = getAppConfig()

const MyAppContent: FC<{ Component: NextComponentType<NextPageContext<any>, any, any>; pageProps: any }> = ({
  Component,
  pageProps,
}) => {
  useUserPageInteraction()

  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style global jsx>{`
        html,
        body,
        * {
          font-family: ${DMSans.style.fontFamily}, sans-serif !important;

          input {
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              font-family: ${DMSans.style.fontFamily}, sans-serif !important;
            }
          }
        }
      `}</style>
      <Head>
        <meta content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" name="viewport" />
        <link href="/images/logo/logoShort.svg" rel="shortcut icon" />
      </Head>
      <GTMScripts />
      <CustomScripts scriptsList={publicRuntimeConfig.customScripts} />
      <Component {...pageProps} />
    </>
  )
}

const MyApp = ({ Component, ...rest }: AppProps) => {
  const { props, store } = wrapper.useWrappedStore(rest)

  return (
    <Provider store={store}>
      <ThemeProvider>
        <MyAppContent Component={Component} pageProps={props.pageProps} />
      </ThemeProvider>
    </Provider>
  )
}

export default MyApp
